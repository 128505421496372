import { createContext } from "react";
import { string, number, tuple } from "yup";

import { BuyContextData } from "./types";

export const BuyContext = createContext<BuyContextData>(null);

export const BASE_VALIDATOR_FIELDS = {
  networkId: number().positive().integer(),
  amount: number().moreThan(-1),
  quoteAmount: number().positive(),
  currencyId: number().positive().integer(),
  paymentMethodId: number().positive().integer(),
  regionId: number().positive().integer(),
  transactionId: string(),
  providerInfo: tuple([
    number().label("providerId").positive(),
    string().label("providerName"),
    string().label("termsLink"),
  ]),
};

export const DEFAULT_SEARCH_ID = "default-search-id";
