import { isEqual } from "lodash";

import coinsAPI from "api/coins";
import { FetchInsightsOption, insightsAPI } from "api/insights";

import { createAppAsyncThunk } from "../duck/types";

export const loadInsights = createAppAsyncThunk<
  {
    insightsMap: Record<InsightCoin["id"], Insight>;
    noKYCInsightsMap: Record<InsightCoin["id"], Insight>;
  },
  FetchInsightsOption[]
>(
  "insights/loadInsights",
  async options => {
    const [insights, noKYCInsights] = await Promise.all([
      insightsAPI.fetchInsights(options),
      insightsAPI.fetchNoKYCCategory(options),
    ]);

    return {
      insightsMap: insights.reduce(
        (acc, insight) =>
          Object.assign(acc, { [insight.cryptocurrency.id]: insight }),
        {},
      ),
      noKYCInsightsMap: noKYCInsights.reduce(
        (acc, insight) =>
          Object.assign(acc, { [insight.cryptocurrency.id]: insight }),
        {},
      ),
    };
  },
  {
    condition(options, { getState }) {
      if (
        !options.length ||
        isEqual(getState().insights.loadOptions, options)
      ) {
        return false;
      }
    },
  },
);

export const loadInsightsDots = createAppAsyncThunk<
  Record<number, Dot[]>,
  Record<InsightCoin["id"], Insight>
>("insights/loadInsightsDots", async (insightsMap, { getState }) => {
  const currenciesSet = new Set<number>();
  const { dotsMap } = getState().insights;
  for (const id of Object.keys(insightsMap)) {
    if (!(id in dotsMap)) {
      currenciesSet.add(Number(id));
    }
  }

  const loadPromises = [];
  for (const id of currenciesSet) {
    loadPromises.push(coinsAPI.fetchDots({ id }));
  }

  const loadResults = await Promise.allSettled(loadPromises);
  const result: Record<number, Dot[]> = {};
  let index = 0;

  for (const id of currenciesSet) {
    const loadResult = loadResults[index++];

    result[id] =
      loadResult.status === "fulfilled"
        ? loadResult.value.map(({ lastUpdated, price }) => ({
            time: lastUpdated,
            value: Number(price),
          }))
        : null;
  }

  return result;
});
