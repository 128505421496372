import { useContext, useRef, MouseEvent, KeyboardEvent } from "react";

import { UseDialogResult } from "types/dialog";

import { Context } from "constants/dialog";

export function useDialog<T>(): UseDialogResult<T> {
  const context = useContext(Context);
  const isOpened = context.state.select(state => state.isOpened);

  return { ...context, isOpened };
}

export const useClickOutside = (close: VoidFunction) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const mouseDownElementRef = useRef<EventTarget>(null);

  return {
    contentRef,
    overlayProps: {
      onMouseDown: (event: MouseEvent) => {
        const portalNode = document.getElementById("headlessui-portal-root");
        if (portalNode && portalNode.contains(event.target as Node)) {
          mouseDownElementRef.current = null;

          return;
        }

        mouseDownElementRef.current = event.target;
      },
      onMouseUp: (event: MouseEvent) => {
        const contentElement = contentRef.current;
        const mouseDownElement = mouseDownElementRef.current;

        mouseDownElementRef.current = null;

        if (
          !contentElement ||
          !mouseDownElement ||
          contentElement.contains(mouseDownElement as Node) ||
          contentElement.contains(event.target as Node)
        ) {
          return;
        }

        close();
      },
      onKeyDown: (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          close();
        }
      },
    },
  };
};
