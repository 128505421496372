import { PaymentBuilder } from "./duck/types";
import { loadCategoriesServer } from "./serverThunks";
import { loadInsights, loadInsightsDots } from "./thunks";

const extraReducers = (builder: PaymentBuilder) => {
  //#region loadCategories

  builder
    .addCase(loadCategoriesServer.pending, state => {
      state.isLoadedCategories = false;
      state.categories = [];
      state.error = null;
    })
    .addCase(loadCategoriesServer.fulfilled, (state, action) => {
      state.isLoadedCategories = true;
      state.categories = action.payload;
    })
    .addCase(loadCategoriesServer.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoadedCategories = true;
    });

  //#endregion

  //#region loadInsights

  builder
    .addCase(loadInsights.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(loadInsights.fulfilled, (state, { meta, payload }) => {
      state.isLoading = false;
      state.loadOptions = meta.arg;
      state.insightsMap = payload.insightsMap;
      state.noKYCInsightsMap = payload.noKYCInsightsMap;
    })
    .addCase(loadInsights.rejected, (state, { meta, error }) => {
      state.isLoading = false;
      state.loadOptions = meta.arg;

      if (error.name !== "AbortError") {
        state.error = error.message;
      }
    });

  //#endregion

  //#region loadInsightsDots

  builder.addCase(loadInsightsDots.fulfilled, (state, { payload }) => {
    state.dotsMap = { ...state.dotsMap, ...payload };
  });

  //#endregion
};

export default extraReducers;
