import buyCryptoAPI, {
  RetrieveBuyLinkResult,
  SearchOption,
  SearchQuotesResult,
} from "api/buy";
import { insightsAPI } from "api/insights";
import coinsAPI from "api/coins";

import { TimeMilliseconds } from "constants/time";

import { addressesThunks } from "store/addresses";

import { sleep } from "operations/async";

import { DEFAULT_SEARCH_ID } from "modules/buy";

import { buySlice } from "./buy";
import { createAppAsyncThunk } from "../duck/types";
import { MAX_SEARCH_REQUEST_COUNT } from "./duck/constants";

export const loadCoins = createAppAsyncThunk(
  "buy/loadCoins",
  () => coinsAPI.fetchLightCryptocurrencies(),
  {
    condition: (_, { getState }) => {
      const {
        buy: { isLoadedCoins },
      } = getState();
      if (isLoadedCoins) {
        return false;
      }
    },
  },
);

export const searchQuotes = createAppAsyncThunk<
  string,
  {
    searchId?: string;
    options: SearchOption;
    updateSearch(searchId: string): void;
    isPrimeLoad?: boolean;
  }
>(
  "buy/searchQuotes",
  async (
    { options, searchId: initialSearchId, updateSearch, isPrimeLoad = false },
    { dispatch, signal },
  ) => {
    let searchId = initialSearchId || DEFAULT_SEARCH_ID;
    let result: SearchQuotesResult | null = null;
    if (searchId !== DEFAULT_SEARCH_ID) {
      try {
        result = await buyCryptoAPI.searchProvidersById(searchId);

        dispatch(buySlice.actions.setProviders(result));
      } catch (error) {
        if (error.message === "Invalid search_id") {
          searchId = DEFAULT_SEARCH_ID;
          updateSearch(searchId);
        } else {
          throw error;
        }
      }
    }

    if (searchId === DEFAULT_SEARCH_ID) {
      searchId = await buyCryptoAPI.searchByOptions(options);
    }

    updateSearch(searchId);

    let count = 0;
    while (!signal.aborted && result?.status !== "completed") {
      if (++count > MAX_SEARCH_REQUEST_COUNT) {
        throw new Error("Invalid timeout");
      }

      await sleep(TimeMilliseconds.second * 2);
      if (signal.aborted) {
        break;
      }

      result = await buyCryptoAPI.searchProvidersById(searchId);

      if (isPrimeLoad || result.status === "completed") {
        dispatch(buySlice.actions.setProviders(result));
      }
    }

    return searchId;
  },
);

interface RetrieveBuyLinkOptions {
  regionId: number;
  quoteAmount: number;
  cryptocurrencyId: number;
  cryptocurrencyLetterId: Coin["letterId"];
  networkId: number;
  currencyId: number;
  paymentMethodId: number;
  serviceId: number;
  address: string;
}

export const retrieveBuyLink = createAppAsyncThunk<
  RetrieveBuyLinkResult,
  RetrieveBuyLinkOptions
>(
  "buy/retrieveBuyLink",
  async (
    {
      address,
      quoteAmount,
      cryptocurrencyId,
      cryptocurrencyLetterId,
      currencyId,
      networkId,
      paymentMethodId,
      serviceId,
      regionId,
    },
    { getState, dispatch },
  ) => {
    const {
      addresses: { entities, ids },
    } = getState();
    if (ids.every(id => entities[id].address !== address)) {
      await dispatch(
        addressesThunks.createAddress({
          address,
          cryptocurrency: cryptocurrencyLetterId,
          network: networkId,
        }),
      );
    }

    return buyCryptoAPI.retrieveBuyLink({
      cryptocurrency: cryptocurrencyId,
      currency: currencyId,
      network: networkId,
      region: regionId,
      currencyAmount: quoteAmount,
      paymentMethod: paymentMethodId,
      service: serviceId,
      walletAddress: address,
    });
  },
);

interface LoadInsightQuoteOptions {
  amount: number;
  baseCurrency: Currency["id"];
  insightId: Insight["id"];
}

export const loadInsightQuote = createAppAsyncThunk<
  BuyProviderSingle,
  LoadInsightQuoteOptions
>("buy/loadInsightQuote", async options => {
  const result = await insightsAPI.fetchInsightQuote(options);

  return {
    baseCurrency: result.baseCurrency,
    cryptocurrency: result.cryptocurrency,
    cryptocurrencyAmount: result.cryptocurrencyAmount,
    currency: result.currency,
    currencyAmount: result.currencyAmount,
    paymentMethods: [
      {
        ...result.paymentMethod,
        code: result.paymentMethod.name,
      },
    ],
    profitRate: result.profitRate,
    service: result.service,
    region: result.region,
    totalCurrencyAmount: result.totalCurrencyAmount,
    totalFee: result.totalFee,
    trueFee: result.trueFee,
  };
});
