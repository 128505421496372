import { createSlice } from "@reduxjs/toolkit";

import type { InsightsState } from "./duck/types";

import reducers from "./reducers";
import extraReducers from "./extraReducers";

export const insightsSlice = createSlice({
  name: "insights",
  initialState: {
    isLoading: true,
    isLoadedCategories: false,
    categories: [],
    insightsMap: {},
    error: null,
    loadOptions: [],
    dotsMap: {},
  } as InsightsState,
  reducers,
  extraReducers,
});
